.app {
  text-align: left;
  margin: auto;
  padding: 4rem;
  font-family: "Times New Roman", "serif";
}

@media (max-width: 1000px) {
  .app {
    padding: 2rem;
  }
}

.title {
  font-size: 2rem;
}

.topLinks {
  display: flex;
  grid-gap: 0.25rem;
  margin: 1rem 0;
}

.sections {
  display: inline-block;
}

details {
  cursor: pointer;
  text-decoration: none;
}

.summary {
  font-style: italic;
  font-family: "Times New Roman", "serif";
  margin: 0.25rem 0;
  text-decoration: none;
  padding-left: 0.2rem;
}

summary:focus {
  outline: none;
}

.info {
  padding: 0.25rem 1rem;
  line-height: 1.5rem;
}

ul {
  padding-left: 2rem;
}

li {
  list-style-type: cjk-heavenly-stem;
  text-decoration: none;

  a {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
}

a {
  color: black;
}

a:visited {
  color: grey;
}

a:hover {
  color: blue;
}

.clickable {
  text-decoration-style: wavy;
  text-decoration-thickness: 1px;
  color: blue;
}

.guestbook {
  vertical-align: middle;
  display: inline-block;
}

.guestbookButton {
  display: inline-block;
  border-radius: 0.25rem;
  border: solid 1px grey;
  font-size: 1rem;
  padding: 0 0.25rem;

  a {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
}

footer {
  margin-top: 1rem;
  display: block;
}

.softButton {
  display: inline-block;
  border-radius: 0.25rem;
  padding: 0.25rem;
  border: solid 1px grey;
  font-size: 1rem;

  a {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
}

.softButton:hover {
  background-color: lightgrey;
}
